import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://twitter.com/mrtnmatt"
      }}>{`Matt`}</a>{`, `}<a parentName="p" {...{
        "href": "https://twitter.com/anthness"
      }}>{`Anthony`}</a>{` and I launched `}<a parentName="p" {...{
        "href": "https://itunes.apple.com/us/app/plangram-share-make-plans/id976660311?ls=1&mt=8"
      }}>{`Plangram`}</a>{`. It is available today, for free on the Apple App Store.`}</p>
    <p><img parentName="p" {...{
        "src": "/plangram.png",
        "alt": "Plangram"
      }}></img></p>
    <p>{`Plangram is a quick, easy, and fun way to make casual plans with your friends with Instagram. Create custom photo based plan cards and share them with your friends via Instagram Direct.`}</p>
    <p>{`Matt & I have always toyed with the idea of creating an app that focuses on easy event planning. A few years ago we started a service which allowed users to share a short unique url that gave users the ability to mark their attendance in a single click.`}</p>
    <p>{`After many iterations of the project we decided it was time to call it a day and focus our energy into building something which was easier to use and didn’t require user authentication.`}</p>
    <p>{`We wanted to build a utility that served a simple purpose but illustrated the beautiful mechanics of iOS design. We believe we did it.`}</p>
    <hr></hr>
    <p>{`See for yourself — `}<a parentName="p" {...{
        "href": "https://itunes.apple.com/us/app/plangram-share-make-plans/id976660311?ls=1&mt=8"
      }}>{`Available on the App Store`}</a>{` and join in the discussion on `}<a parentName="p" {...{
        "href": "https://producthunt.com/tech/plangram-for-ios"
      }}>{`ProductHunt`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      